import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import "./index.css"
import InstagramIcon from "../images/instagram.svg"
import GithubIcon from "../images/github.svg"
import TwitterIcon from "../images/twitter.svg"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>🙃</h1>
    <h2>Staging</h2>
    <ul>
      <li>
        <span role="img" aria-label="tada emoji">
          🎉
        </span>
        &nbsp;&nbsp;enjoys generative art
      </li>
    </ul>
  </Layout>
)

export default IndexPage
